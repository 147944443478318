import { isPhoneNumberValid } from '@pebl/ui';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ApiResourceActionOptionsProperty } from '@/api/types';
import { isBoolean } from '@/utils/typeguards';

import { CommonActionPropertyOptions } from '../../../types';
import { ActionPropertyInputPhoneNumberView } from './phonenumber-view';

type ActionPropertyInputPhoneNumberProps = {
  id: string;
  data: ApiResourceActionOptionsProperty;
  options: CommonActionPropertyOptions;
};

export function ActionPropertyInputPhoneNumber({
  data,
  id,
  options,
}: ActionPropertyInputPhoneNumberProps) {
  const { errorMessage, required } = options;
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      name={id}
      rules={{
        validate: (value) => {
          return isPhoneNumberValid(value)
            ? true
            : t && t('actions.actions_form.errors.invalid_phone_number');
        },
        required: isBoolean(required)
          ? {
              value: required,
              message: (t && t('actions.actions_form.errors.required')) || '',
            }
          : undefined,
      }}
      render={({ field }) => (
        <ActionPropertyInputPhoneNumberView
          label={data.title}
          error={options.isError}
          // We can't just spread field here due to ref problems
          innerRef={field.ref}
          name={field.name}
          value={field.value}
          disabled={field.disabled}
          onChange={field.onChange}
          onBlur={field.onBlur}
          helperText={errorMessage}
        />
      )}
      control={control}
    />
  );
}
