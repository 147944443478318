import React from 'react';
import { RouterProvider } from 'react-router-dom';

import { setClientId } from '@/stores/qng-data-store';

import router from './router';

/**
 * The base Router for QNG
 */
export function AppRouter() {
  const [showDevtools, setShowDevtools] = React.useState(false);

  React.useEffect(() => {
    window.toggleDevtools = () => setShowDevtools((old) => !old);

    /**
     * When the site first loads, we should have the dynamic configuation.js
     * file loaded by now (as it'll load well before any React runs in theory)
     * So update the local datastore with that value.
     */
    setClientId(window.globalQngConfig?.clientId);
  }, []);

  return (
    <>
      {showDevtools && (
        <React.Suspense fallback={false}>
          <ReactQueryDevtoolsProduction />
        </React.Suspense>
      )}
      <RouterProvider router={router} future={{ v7_startTransition: true }} />
    </>
  );
}

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    }),
  ),
);
