import { AnimatePresence } from 'motion/react';
import React from 'react';
import { useLocation, useOutlet } from 'react-router-dom';

/**
 * This is required as an alternative to us just directly using
 * <Outlet /> in our layout component to support exit animations.
 * This is because AnimatePresence MUST have the animated motion component
 * directly as a child for exit animations to work, but using <Outlet />
 * does things with OutletContext.Provider in between, breaking the
 * direct child requirement.
 *
 * This is discussed here by the framer-motion creator:
 * https://github.com/framer/motion/issues/493#issuecomment-618902963
 *
 * And then an article I found with the below suggestion:
 * https://medium.com/@antonio.falcescu/animating-react-pages-with-react-router-dom-outlet-and-framer-motion-animatepresence-bd5438b3433b
 *
 * In Layout where we use this component, we could swap it back out for
 * <Outlet /> if we don't need exit animations in the future.
 *
 */
export function AnimatedOutlet(): React.JSX.Element {
  const location = useLocation();
  const element = useOutlet();

  return (
    <AnimatePresence mode="wait" initial={true}>
      {element && React.cloneElement(element, { key: location.pathname })}
    </AnimatePresence>
  );
}
