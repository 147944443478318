import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ApiResourceActionOptionsProperty } from '@/api/types';

import { mapActionOptionsPropertyToRules } from '../../../../../utils/map-action-property-rules';
import { CommonActionPropertyOptions } from '../../../types';
import { ActionPropertyInputTextView } from './text-view';

type ActionPropertyInputTextProps = {
  id: string;
  data: ApiResourceActionOptionsProperty;
  options: CommonActionPropertyOptions;
};

export function ActionPropertyInputText({
  data,
  id,
  options,
}: ActionPropertyInputTextProps) {
  const { errorMessage, required } = options;
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      name={id}
      rules={mapActionOptionsPropertyToRules(data, required, t)}
      render={({ field }) => (
        <ActionPropertyInputTextView
          label={data.title}
          defaultValue={''}
          error={options.isError}
          // We can't just spread field here due to ref problems
          innerRef={field.ref}
          name={field.name}
          value={field.value}
          disabled={field.disabled}
          onChange={field.onChange}
          onBlur={field.onBlur}
          helperText={errorMessage}
        />
      )}
      control={control}
    />
  );
}
