import React from 'react';

import { AnimatedRoute } from '../animated-route';

const DeepLinks = React.lazy(
  () => import('@/features/deeplinks/components/index'),
);

export function DeepLinkRoute() {
  return (
    <AnimatedRoute animationKey="deeplink">
      <DeepLinks />
    </AnimatedRoute>
  );
}
