import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import qngLanguageDetector from './qng-language-detector';
import translationResources from './translation-resources';

const languageDetector = new LanguageDetector();
languageDetector.addDetector(qngLanguageDetector);

i18n
  /*
   * We may want to use the Backend middleware to load translations from
   * the main QNG API (if we choose to provide them from there eventually).
   */
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: translationResources,
    fallbackLng: 'en',
    debug: true,

    detection: {
      order: [qngLanguageDetector.name],
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    react: {
      useSuspense: true,
    },
  });

export default i18n;
