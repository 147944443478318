import { createHashRouter } from 'react-router-dom';

import { Error404 } from '@/components/error-404';

import { Layout } from './layout';
import routes from './routing';

// const router = createBrowserRouter([
const router = createHashRouter(
  [
    {
      element: <Layout />,
      errorElement: <Error404 />,
      children: routes,
    },
  ],
  {
    future: {
      /**
       * Enabling the below relativeSplatPath option is tricky.
       * https://reactrouter.com/en/6.28.2/upgrading/future#v7_relativesplatpath
       * https://github.com/remix-run/react-router/blob/main/CHANGELOG.md#futurev7_relativesplatpath
       *
       * It requires us to split our top level routes into 2 separate sub routes
       * as seen at the above link, however due to how we define our routes in the
       * RoutePathConstants file (which then gets turned into the actual routes)
       * we don't have that kind of flexibility currently.
       *
       * This isn't the end of the world as this won't be an issue until we upgrade
       * React Router to v7 (if we ever do as it's a big upgrade).
       */
      // v7_relativeSplatPath: true,
    },
  },
);

export default router;
