import {
  QueryKey,
  usePrefetchQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';

import { getBasket, resourceQueryKey } from '@/api/functions/basket';
import { ApiBasketData, CommonQueryProps } from '@/api/types';
import { commonQueryPropsToKey } from '@/api/utils/common-query-props-to-key';
import {
  useSelectQngAuth,
  useSelectQngCommonQueryProps,
} from '@/stores/selectors';
import { BasketClientData } from '@/types/basket';
import { ApiQueryDefinition } from '@/types/core';

/**
 * This function is used to generate the query key for this query.
 *
 * @param object - Any parameters needed to generate the query key
 * @returns a valid string[] query key, used in the wrapped query further below
 */
export function getQueryKey(params: CommonQueryProps): QueryKey {
  return [...resourceQueryKey(), ...commonQueryPropsToKey(params)];
}

const queryDefinition: ApiQueryDefinition<
  typeof getQueryKey,
  typeof getBasket
> = (
  props: Omit<Parameters<typeof getBasket>[0], 'signal'> &
    Parameters<typeof getQueryKey>[0],
) => ({
  queryKey: getQueryKey(props),
  queryFn: ({ signal }: { signal: AbortSignal }) =>
    getBasket({ signal, ...props }),
  staleTime: 300000,
});

export function useGetBasket() {
  const { authToken } = useSelectQngAuth();
  const commonQueryProps = useSelectQngCommonQueryProps();

  const {
    data: queryData,
    isFetching,
    isPending,
    isRefetching,
    isStale,
  } = useSuspenseQuery(queryDefinition({ authToken, ...commonQueryProps }));

  const { actions, client_data, data: apiData, links } = queryData;

  const data: ApiBasketData = {
    currency: apiData.currency,
    currency_symbol: apiData.currency_symbol,
    expires_in_mins: apiData.expires_in_mins,
    expires_in_secs: apiData.expires_in_secs,
    item_count: apiData.item_count ?? 0,
    items: apiData.items ?? [],
    tax_rate: apiData.tax_rate,
    total: apiData.total,
  };

  const clientData: BasketClientData = {
    emptyTitle: client_data.empty_title,
    emptyDescription: client_data.empty_description,
    expiryMessage: client_data.expiry_message,
    priceChangeTitle: client_data.price_change_title,
    quantity: client_data.quantity,
    total: client_data.total,
    subtotal: client_data.subtotal,
    tax: client_data.tax,
  };

  return {
    data,
    clientData,
    links,
    actions,
    isRefetching,
    isFetching,
    isPending,
    isStale,
  };
}

export function useGetBasketPrefetch() {
  const { authToken } = useSelectQngAuth();
  const commonQueryProps = useSelectQngCommonQueryProps();

  usePrefetchQuery(queryDefinition({ authToken, ...commonQueryProps }));
}
