import { ApiResourceActionResponse, CommonQueryProps } from '@/api/types';
import {
  setAuthToken,
  setCulture,
  setStoredResponseDataItem,
} from '@/stores/qng-data-store';
import log from '@/utils/logging';
import { isString } from '@/utils/typeguards';

import { axios } from '../../axios';
import { getCommonHeaders } from '../../utils/headers';

/**
 * An optional callback that can be used to perform some action
 * before the user is redirected to a new location.
 */
export type BeforeRedirectCallback = () => void;

type SubmitActionProps = {
  /**
   * The URL to submit the action to.
   */
  url: string;
  /**
   * The data to submit to the action.
   * This should match the shape of data the action options declare.
   */
  submitData?: unknown;
  /**
   * The method to use for the action.
   * Defaults to 'POST'.
   */
  method?: 'POST' | 'GET';
  /**
   * An optional callback that can be used to perform some action
   * before the user is redirected to a new location.
   */
  beforeRedirect?: BeforeRedirectCallback;

  /**
   * Passed up to the API layer to prevent redirection if the response
   * indicated it should redirect (via a 301 + Location header OR in
   * the response body).
   *
   * Normally we would want to allow the API action to redirect us as
   * it sees fit, however sometimes this may need to be overridden.
   */
  preventRedirect?: boolean;

  /**
   * Additional options for the action.
   */
  options?: CommonQueryProps & {
    signal?: AbortSignal;
    contentType?: string;
  };
};

/**
 * This function differs from the others as this is used for submitting
 * actions to unknown arbitrary endpoints (based on what the action specifies).
 */

export async function submitAction({
  beforeRedirect,
  method,
  options = {},
  preventRedirect,
  submitData,
  url,
}: SubmitActionProps): Promise<ApiResourceActionResponse> {
  const { data } = await axios
    .request<ApiResourceActionResponse>({
      url,
      signal: options?.signal,
      data: submitData,
      method: method ?? 'POST',
      headers: {
        ...getCommonHeaders(options),
        'Content-Type': options.contentType ?? 'application/json',
      },
    })
    .then((response) => {
      // STORED RESPONSE (PERSISTENCE) DATA LOGIC
      /**
       * !! WARNING !! - This is deprecated / We shouldn't be using it anymore and really should remove it
       *
       * Data in the storedResponseData needs to be persisted (via local storage)
       * however we're using Zustand as a global store (which handles this for us).
       * The tricky bit is that in this case we don't know what data could be returned
       * and so we can't prep the Zustand store with known types, instead having
       * to rely on an arbitrary object.
       *
       * In the case of the authToken, since we NEED that in so many places and fully
       * know of it's existence, I'm making the decision to "assume" that will be
       * available in the response data (of certain actions e.g. signin).
       */
      const storedResponseData = response.data?.storedResponseData;
      if (storedResponseData) {
        if ('authCode' in storedResponseData) {
          const authToken = storedResponseData.authCode;
          if (isString(authToken)) {
            setAuthToken({
              authToken,
              isTokenAnonymous: false,
            });
          }
        }

        /*
         * For all the other storedResponseData items (other than authToken), we
         * will just store them in the global store 'storedResponseData' property.
         */
        for (const [key, value] of Object.entries(storedResponseData).filter(
          ([key]) => key !== 'authCode',
        )) {
          setStoredResponseDataItem(key, value);
        }
      }

      if (response.data?.headers) {
        const apiDefinedHeaders = response.data.headers;

        if ('culture' in apiDefinedHeaders) {
          const culture = apiDefinedHeaders.culture;
          if (isString(culture)) {
            /**
             * - Update the culture in our global store
             * i18n now pulls the preferred culture from our global store, so the above
             * should be all that's required. See our custom LanguageDetector implementation
             * in src/i18n/qng-language-detector.ts for more information.
             */
            setCulture(culture);
          }
        }
      }

      return response;
    })
    .then((response) => {
      if (preventRedirect) {
        /*
         * This 'then' handles any redirection, so if this request explicitly requested
         * to prevent this then just skip this entire block by returning the response.
         */
        return response;
      }
      // REDIRECTION LOGIC
      /**
       * We need to determine if this is the right place for this redirection logic
       * as it may cause problems elsewhere - We may want to move this down to the actual
       * query that calls this function.
       * An action that succeeds, may:
       *  - Have a Location header that we should redirect to
       *  - Have a body that may include a redirection link { "link": "#/home/?" }
       *
       * If any of the redirection behaviours above are present then capture that
       * here and redirect the user to the appropriate location.
       */
      let redirectUrl: string | undefined = undefined;

      if (
        response.status === 200 &&
        response.data &&
        typeof response.data === 'object' &&
        'link' in response.data
      ) {
        redirectUrl = isString(response.data.link)
          ? response.data.link
          : undefined;
      } else if (response.status === 201 && 'location' in response.headers) {
        /*
         * A redirect COULD be off the site entirely with an absolute URL
         * or it could be a relative path URL.
         * Naively for now we can just check for a protocol and assume it's
         * an absolute URL otherwise we can assume it's relative.
         * Note: Possibly rethink this all together.
         */
        const location = response.headers['location'];
        redirectUrl = isString(location) ? location : undefined;
      }

      if (redirectUrl) {
        if (beforeRedirect) {
          beforeRedirect?.();
        }

        if (isString(redirectUrl)) {
          const decodedUrl = decodeURI(redirectUrl);
          if (decodedUrl.startsWith('http')) {
            log.debug('Redirecting to:', decodedUrl);
            window.location.href = decodedUrl;
          } else {
            // This is a relative path
            const hashPath = decodedUrl.substring(decodedUrl.indexOf('#') + 1);
            log.debug('Redirecting to Hash:', hashPath);
            window.location.hash = hashPath;
          }
        }
      }

      return response;
    });

  return data;
}
