import axios, { isAxiosError } from 'axios';

import { CommonQueryProps } from '@/api/types';
import { getCommonHeaders } from '@/api/utils/headers';
import { AppSettings } from '@/types/settings';
import log from '@/utils/logging';

/* ------------------------- Constants ----------------------- */

const SITE_SETTINGS_ENDPOINT = '/settings.json';

/* -------------------------- Types -------------------------- */

export type ApiSiteSettings = {
  settings: AppSettings;
};

export type ApiThemeSettings = {
  settings: AppSettings;
};

/* ----------------------------------------------------------- */

/**
 * Load the site settings from the site settings endpoint `/settings.json`, which
 * is provided by the Cloudfront distribtion and is the combination of any host and
 * variant settings for this specific deployment.
 * If the settings file is not found, it will return undefined.
 *
 * @param options.signal An optional AbortSignal to cancel the request
 * @returns The site settings or undefined if the settings file is not found
 */
export async function getSiteSettings(
  options: {
    signal?: AbortSignal;
  } & CommonQueryProps = {},
): Promise<ApiSiteSettings | undefined> {
  try {
    const response = await axios.get<ApiSiteSettings>(SITE_SETTINGS_ENDPOINT, {
      signal: options?.signal,
      headers: { ...getCommonHeaders(options), Accept: 'application/json' },
      validateStatus: (status) => status >= 200 && status < 400,
    });

    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      log.debug('Request for site settings was cancelled - ignoring');
      return undefined;
    }
    if (isAxiosError(err)) {
      log.error('Error loading site settings: ', err);
      if (err.response?.status === 404) {
        log.debug('No site settings found');
        return undefined;
      }
      return undefined;
    } else {
      log.error('Error loading site settings: ', err);
      return undefined;
    }
  }
}

/**
 * Load the theme settings from the given theme path.
 * If the settings file is not found, it will return undefined.
 *
 * @param options.themePath The path to the theme settings file - e.g. `/themes/my-theme/settings.json`
 * @param options.signal An optional AbortSignal to cancel the request
 * @returns The theme settings or undefined if the settings file is not found
 */
export async function getThemeSettings(
  options: {
    themePath: string;
    signal?: AbortSignal;
  } & CommonQueryProps,
): Promise<ApiThemeSettings | undefined> {
  try {
    log.debug('Loading theme settings from: ', options.themePath);

    const response = await axios.get<ApiThemeSettings>(options.themePath, {
      signal: options?.signal,
      headers: { ...getCommonHeaders(options), Accept: 'application/json' },
      validateStatus: (status) =>
        (status >= 200 && status < 400) || status === 404,
    });

    /*
     * Since a theme is entirely allowed to not have a settings.json file
     * we don't want to blow up or spout loads of errors if it's not found.
     * Instead just warn and return undefined.
     */
    if (response.status === 404) {
      log.warn('No theme settings found');
      return undefined;
    }

    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      log.debug('Request for theme settings was cancelled - ignoring');
      return undefined;
    }
    if (isAxiosError(err)) {
      log.error('Error loading theme settings: ', err);
      return undefined;
    } else {
      log.error('Error loading theme settings: ', err);
      return undefined;
    }
  }
}
