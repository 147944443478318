import { ClaimType } from '@/types/entitlements';

import { parseRoute } from './parse-route';

/**
 * The 'key' prop is the key actually used by React Router to match
 * the route.
 *
 * The 'link' prop is the generator for this route that can be used
 * elsewhere on the site, handling paths params etc.
 *
 * The primary reason this is separate from the key is that a key
 * can contain path params e.g. `/tags/:tagId` which is valid for
 * React Router but not for a link.
 * It could also contain a greedy '*' param e.g. `/onboarding/ticket/*`
 * which again is valid for React Router but NOT as a link to get
 * to that route.
 */
const RoutePathConstants = {
  ACCOUNT: {
    key: '/account',
    link: () => '/account',
  },
  ACTIVATE: {
    key: '/flow/entitlements/ticket/activate',
    link: () => '/flow/entitlements/ticket/activate',
  },
  ATTRACTION_DETAILS: {
    key: '/attractions/:attractionId',
    link: ({ attractionId }: { attractionId: string }) =>
      parseRoute('/attractions/:attractionId', { attractionId }),
  },
  ATTRACTIONS: {
    key: '/home', // This *should* be attractions, but the old codebase/API expect home for now
    link: () => '/home',
  },
  BASKET: {
    key: '/basket',
    link: () => '/basket',
  },
  CHANGE_LANGUAGE: {
    key: '/change-language',
    link: () => '/change-language',
  },
  COOKIE_POLICY: {
    key: '/cookie-policy',
    link: () => '/cookie-policy',
  },
  DEEPLINK: {
    key: '/deeplink/*',
    link: () => '/deeplink', // Note: This isn't really a thing for deeplinks as they aren't internally navigable
  },
  DEVELOPER: {
    key: '/developer',
    link: () => '/developer',
  },
  HOME: {
    key: '/',
    link: () => '/',
  },
  FLOW_ENTITLEMENT_CLAIMING: {
    key: '/flow/entitlements/:claimType/*',
    link: (claimType: ClaimType) =>
      parseRoute('/flow/entitlements/:claimType', { claimType }),
  },
  PAYMENT_COMPLETE: {
    key: '/shop/payment',
    link: () => '/shop/payment',
  },
  SHOP: {
    key: '/shop/:type?',
    link: ({ type }: { type: string }) => parseRoute('/shop/:type', { type }),
  },
  SIGN_IN: {
    key: '/signin',
    link: () => '/signin',
  },
  SIGN_OUT: {
    key: '/signout',
    link: () => '/signout',
  },
  TAG: {
    key: '/tags/:tagId',
    link: ({ tagId }: { tagId: string }) =>
      parseRoute('/tags/:tagId', { tagId }),
  },
  TAG_ACTION: {
    key: '/tags/:tagId/actions/:actionId',
    link: ({ actionId, tagId }: { actionId: string; tagId: string }) =>
      parseRoute('/tags/:tagId/actions/:actionId', { tagId, actionId }),
  },
};

export default RoutePathConstants;
