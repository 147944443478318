import { Controller, useFormContext } from 'react-hook-form';

import { ApiResourceActionOptionsProperty } from '@/api/types';

import { mapActionOptionsPropertyToRules } from '../../../../utils/map-action-property-rules';
import { CommonActionPropertyOptions } from '../../types';
import {
  ActionPropertySelectItemData,
  ActionPropertySelectView,
} from './select-view';

type ActionPropertySelectProps = {
  id: string;
  data: ApiResourceActionOptionsProperty;
  options: CommonActionPropertyOptions;
};

export function ActionPropertySelect({
  data,
  id,
  options,
}: ActionPropertySelectProps) {
  const { errorMessage, required } = options;
  const { control } = useFormContext();

  const selectItems = (data.enum ?? [])
    // Filter out items that don't have data or text since we require both
    .filter((item) => !!item.data && !!item.text)
    .map<ActionPropertySelectItemData>((enumItem) => ({
      value: enumItem.data as string,
      displayText: enumItem.text as string,
    }));

  const defaultValue = (data.enum ?? []).find((item) => item.default)?.data;

  return (
    <Controller
      name={id}
      rules={mapActionOptionsPropertyToRules(data, required)}
      render={({ field }) => (
        <ActionPropertySelectView
          label={data.title}
          error={options.isError}
          items={selectItems}
          defaultValue={defaultValue}
          // We can't just spread field here due to ref problems
          innerRef={field.ref}
          name={field.name}
          value={field.value}
          disabled={field.disabled}
          onChange={field.onChange}
          onBlur={field.onBlur}
          helperText={errorMessage}
        />
      )}
      control={control}
    />
  );
}
