import { ErrorIcon, Input } from '@pebl/ui';

import { CommonActionPropertyViewProps } from '../../../types';

export function ActionPropertyInputEmailView({
  defaultValue,
  disabled,
  error,
  helperText,
  innerRef,
  label,
  name,
  onBlur,
  onChange,
  value,
}: {
  error?: boolean;
  label?: string;
  helperText?: string;
} & CommonActionPropertyViewProps<string>) {
  return (
    <Input
      type="email"
      /*
       * This autocomplete is good for signing in, but if this form input
       * is used anywhere else to collect an email (send a friend?), then it will
       * cause that to be auto-filled with the user's email address if they
       * use a password manager - possibly annoying but not a deal breaker for now.
       */
      autoComplete="username"
      label={label}
      error={error}
      name={name}
      value={value}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={(e) => onChange?.(e.target.value)}
      onBlur={onBlur}
      helperText={helperText}
      helperTextIcon={<ErrorIcon outlined className="size-4" />}
      className="qng-action-property-input-email"
      ref={innerRef}
      // Data Fields - Primarily for debugging / testing
      data-action-option-type="string"
      data-action-option-format="email"
    />
  );
}
