import { CustomDetector } from 'i18next-browser-languagedetector';
import log from 'loglevel';

import { useQngDataStore } from '@/stores/qng-data-store';

/**
 * Our custom language detector for i18n. This will attempt to get the current culture
 * from the QNG data store and set the i18n language accordingly.
 * There is a fallback mechanic if the store has no culture set so we can allow
 * a default culture per deploy (or deploy sub-variant) that makes sense to the geographical
 * location of the deployment (which is otherwise tricky since QNG is multi-tenant).
 */
const detector: CustomDetector = {
  name: 'QngLanguageDetector',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  lookup(_options) {
    // log.debug(options);
    const currentStoreCulture = useQngDataStore.getState().culture;
    log.debug(
      '🔠 Trying to get current culture from QNG data store to set i18n language',
    );

    /**
     * The final culture used has a fallback chain:
     * 1. The culture in the QNG data store (which will be set if the user uses the change language action)
     * 2. The fallback culture set in the global QNG config provided from the hosts.json file (unique per deploy or deploy sub-variant)
     * 3. A default culture set in the build (this is common to ALL deployments by the nature of us being multi-tenant)
     * 4. The default culture of 'en' (if all else fails)
     */
    return (
      currentStoreCulture ??
      window.globalQngConfig?.fallbackCulture ??
      import.meta.env.VITE_DATA_STORE_NAME_PREFIX ??
      'en'
    );
  },
};

export default detector;
