import {
  QueryKey,
  usePrefetchQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';

import { getRoot, resourceQueryKey } from '@/api/functions/root';
import { CommonQueryProps } from '@/api/types';
import { commonQueryPropsToKey } from '@/api/utils/common-query-props-to-key';
import { setAuthToken } from '@/stores/qng-data-store';
import {
  useSelectQngAuth,
  useSelectQngCommonQueryProps,
} from '@/stores/selectors';
import { ApiQueryDefinition } from '@/types/core';
import log from '@/utils/logging';

export function getQueryKey(params: CommonQueryProps): QueryKey {
  return [...resourceQueryKey(), ...commonQueryPropsToKey(params)];
}

const queryDefinition: ApiQueryDefinition<
  typeof getQueryKey,
  typeof getRoot
> = (props: CommonQueryProps) => ({
  queryKey: getQueryKey(props),
  queryFn: ({ signal }: { signal: AbortSignal }) =>
    getRoot({ signal, ...props }).then((response) => {
      // Since the root call has some data we want to pull out into our
      // global state, we can ensure that always gets done by handling
      // that here.
      if (response?.data?.auth_code) {
        /*
         * The API has provided us with an anonymous auth code, so we
         * can store that in our global state to be used in all our
         * subsequent requests (until the user may sign in).
         */
        try {
          log.info(
            'Updating auth token to anonymous auth code provided by API',
          );
          setAuthToken({
            authToken: response.data.auth_code,
            isTokenAnonymous: true,
          });
        } catch (error) {
          /*
           * TODO: Decide if we want this to throw an error (or rethrow) so an
           * error boundary catches it, OR let the site continue working
           * even though this failed (anon baskets wouldn't function etc).
           */

          log.error(
            'Error updating auth token to anonymous auth code provided by API',
            error,
          );
        }
      }

      /*
       * Ensure we return the response so consumers of this further down
       * actually have access to the data.
       */
      return response;
    }),
});

export function useGetRoot() {
  const { authToken, isAuthTokenAnonymous } = useSelectQngAuth();
  const commonQueryProps = useSelectQngCommonQueryProps();

  return useSuspenseQuery(
    queryDefinition({ authToken, isAuthTokenAnonymous, ...commonQueryProps }),
  );
}

// This hook is purely for us to prefetch with if required
export function useGetRootPrefetch() {
  const { authToken, isAuthTokenAnonymous } = useSelectQngAuth();
  const commonQueryProps = useSelectQngCommonQueryProps();

  usePrefetchQuery(
    queryDefinition({ authToken, isAuthTokenAnonymous, ...commonQueryProps }),
  );
}
