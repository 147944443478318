/**
 * Generally with Zustand we should be selecting data in the components
 * that use it (see the Zustand docs for more info). Essentially
 * const { xyz } = useQngDataStor(store => store.xyz) is the pattern.
 *
 * However in some cases we may want to have a compound selector
 * defined here that returns multiple parameters together, without repeating
 * that logic in multiple components.
 * The obvious initial example is that whenever we get the authToken we almost
 * always also need to get isAuthTokenAnonymous to know if the user is signed in
 * or using an anonymous token.
 *
 * We do that in here by wrapping the compound useQngDataStore hook with
 * our own hook.
 *
 * I would recommend adding selectors here sparingly! and preferring the
 * correct Zustand pattern and to discuss any additions to this file.
 *
 * https://zustand.docs.pmnd.rs/guides/prevent-rerenders-with-use-shallow
 */

import { useShallow } from 'zustand/react/shallow';

import { CommonQueryProps } from '@/api/types';
import { AppSettingsKey, AppSettingsValue } from '@/types/settings';

import { useQngDataStore } from './qng-data-store';

/**
 * Get the authToken and isAuthTokenAnonymous from the QngDataStore
 *
 * @returns {authToken: string, isAuthTokenAnonymous: boolean}
 */
export const useSelectQngAuth = () => {
  return useQngDataStore(
    useShallow((state) => ({
      authToken: state.authToken,
      isAuthTokenAnonymous: state.isTokenAnonymous,
    })),
  );
};

export const useSelectQngCommonQueryProps = (): CommonQueryProps => {
  return useQngDataStore(
    useShallow((state) => ({
      authToken: state.authToken,
      isAuthTokenAnonymous: state.isTokenAnonymous,
      culture: state.culture,
      clientId: state.clientId,
    })),
  );
};

/**
 * A generic utility selector intended to allow us to access
 * our App settings from the store in a common way.
 * If you have unique cases where creating your own selector for
 * a specific setting or group of settings is more appropriate
 * then do that instead.
 */
export const useSelectQngAppSetting = <T = AppSettingsValue>(
  settingName: AppSettingsKey,
  defaultValue?: T,
): T | undefined => {
  return useQngDataStore(
    useShallow((state) => {
      return (state.appSettings[settingName] ?? defaultValue) as T | undefined;
    }),
  );
};
