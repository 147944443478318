import { FieldPath, FieldValues, RegisterOptions } from 'react-hook-form';

import { ApiResourceActionOptionsProperty } from '@/api/types';
import { TranslationComponentProps } from '@/types/translation';
import { isBoolean, isNumber } from '@/utils/typeguards';

/**
 * Map an API Action Options Property to a set of rules that can
 * be used by react-hook-form.
 *
 * @param prop - The API Action Options Property to map
 * @param required - Whether the property is required or not
 * @returns - The rules to directly apply to a react-hook-form Controller
 */
export function mapActionOptionsPropertyToRules(
  prop: ApiResourceActionOptionsProperty,
  required: boolean = false,
  t?: TranslationComponentProps['t'],
): Omit<
  RegisterOptions<FieldValues, FieldPath<FieldValues>>,
  'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
> {
  return {
    required: isBoolean(required)
      ? {
          value: required,
          message: (t && t('actions.actions_form.errors.required')) || '',
        }
      : undefined,
    min: isNumber(prop.min)
      ? {
          value: prop.min,
          message:
            prop.errors?.['min'] ??
            ((t &&
              t('actions.actions_form.errors.min', {
                min: prop.min?.toString(),
              })) ||
              ''),
        }
      : undefined,
    max: isNumber(prop.max)
      ? {
          value: prop.max,
          message:
            prop.errors?.['max'] ??
            ((t &&
              t('actions.actions_form.errors.max', {
                min: prop.max?.toString(),
              })) ||
              ''),
        }
      : undefined,
    minLength: isNumber(prop.minLength)
      ? {
          value: prop.minLength,
          message:
            prop.errors?.['minLength'] ??
            ((t &&
              t('actions.actions_form.errors.min_length', {
                minLength: prop.minLength?.toString(),
              })) ||
              ''),
        }
      : undefined,
    maxLength: isNumber(prop.maxLength)
      ? {
          value: prop.maxLength,
          message:
            prop.errors?.['maxLength'] ??
            ((t &&
              t('actions.actions_form.errors.max_length', {
                maxLength: prop.maxLength?.toString(),
              })) ||
              ''),
        }
      : undefined,
    pattern: prop.pattern
      ? {
          value: new RegExp(prop.pattern),
          message:
            prop.errors?.['pattern'] ??
            ((t && t('actions.actions_form.errors.pattern')) || ''),
        }
      : undefined, // WARNING This could throw if regex is invalid
  };
}
