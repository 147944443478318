import { ErrorIcon, InternationalPhoneInput } from '@pebl/ui';

import { CommonActionPropertyViewProps } from '../../../types';

export function ActionPropertyInputPhoneNumberView({
  defaultValue,
  disabled,
  error,
  helperText,
  innerRef,
  label,
  name,
  onBlur,
  onChange,
  value,
}: {
  error?: boolean;
  label?: string;
  helperText?: string;
} & CommonActionPropertyViewProps<string>) {
  return (
    <InternationalPhoneInput
      showCountrySelector
      label={label}
      error={error}
      name={name}
      value={value}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={(e) => onChange?.(e)}
      onBlur={onBlur}
      className="qng-action-property-input-phonenumber"
      helperText={helperText}
      helperTextIcon={<ErrorIcon outlined className="size-4" />}
      ref={innerRef}
      // Data Fields - Primarily for debugging / testing
      data-action-option-type="string"
      data-action-option-format="phone"
    />
  );
}
