import { Banner, BannerMessage, Skeleton } from '@pebl/ui';
import { motion } from 'motion/react';

import { TranslationComponentProps } from '@/types/translation';

type SiteBannerViewProps = {
  loading?: boolean;
  message?: string;
  closeable?: boolean;
  onClose?: () => void;
  icon?: React.ReactNode;
  variant?:
    | 'default'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | null
    | undefined;
} & TranslationComponentProps;

export function SiteBannerView({
  closeable,
  icon,
  loading,
  message,
  onClose,
  variant,
}: SiteBannerViewProps) {
  return (
    <motion.div
      initial={{ translateY: -80 }}
      animate={{ translateY: 0 }}
      exit={{ translateY: -80 }}
      transition={{ ease: 'easeInOut', duration: 0.5 }}
      className="z-10"
    >
      <Banner
        closeable={closeable}
        startIcon={icon}
        onClose={onClose}
        variant={variant}
        className="qng-banner"
      >
        <BannerMessage className="flex items-center">
          {loading ? <Skeleton variant="text" /> : message}
        </BannerMessage>
      </Banner>
    </motion.div>
  );
}
