import dayjs from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';
import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { ISO8601DateTimeString } from '@/api/types';
import {
  GlobalNotification,
  GlobalNotificationType,
} from '@/types/notifications';
import {
  AppSettings,
  AppSettingsKey,
  AppSettingsValue,
} from '@/types/settings';
import { Guid } from '@/types/utility-types';
import log from '@/utils/logging';

/**
 * NOTES:
 * - I have setup the devtools middleware which means we can _mostly_ use the
 *   Redux DevTools Extension to inspect the store and actions in Chrome which
 *   is VERY useful and something I would recommend. I was concerned not using
 *   Redux and would loose us this functionality which has been crucial in other
 *   projects to reason about state and action changes AND time-travel
 *   debugging.
 *
 * - If we start wanting to improve performance then one option _may_ be to
 *   memoize the selectors for the store - https://github.com/pmndrs/zustand/discussions/387
 *   (https://github.com/reduxjs/reselect - is one option)
 */

type QngDataState = {
  /**
   * -------------- Authentication --------------
   */

  /**
   * The current users auth token (could be anonymous I.E. not signed in
   * and provided by the API to use). This gets used in all API requests
   * to authenticate the user.
   */
  authToken: string | undefined;
  /**
   * The API provides anonymous tokens for users who are not signed in.
   * This is so that functionality like the basket can still work for
   * users who are not signed in.
   * This flag is used to determine if the token is an anonymous token otherwise
   * we would always consider a user "signed in" as there would ALWAYS be an
   * authToken set.
   */
  isTokenAnonymous: boolean;

  culture: string | undefined;

  clientId: string | undefined;

  /**
   * !WARNING/NOTE: This will soon become deprecated / removed on the API
   *
   * API Action responses can contain a `storedResponseData` object which
   * can contain arbitrary data that we need to store in the global store
   * (and ultimately persist to local storage).
   * We don't know what this data will be ahead of time.
   */
  storedResponseData: Record<string, unknown>;

  /**
   * When the onboarding overlay was last dismissed by the user.
   * We use this to determine if we should show the onboarding overlay
   * again or not.
   *
   * This gets persisted as an ISO8601 string in local storage but is
   * stored as a DayJS object in the store.
   */
  onboardingOverlayDismissedTime: dayjs.Dayjs;

  /**
   * ---------------- Notifications ----------------
   */
  notifications: GlobalNotification[];
  notificationsBlacklist: Record<string, ISO8601DateTimeString | undefined>;
  notificationHistory: GlobalNotification[];

  /**
   * App settings provided by the /settings.json file which
   * is created from the hosts.json file in the versions repo
   * and then also combined with any theme settings if a theme
   * is set.
   */
  appSettings: AppSettings;

  attractionsSort?: string;
};

/* ----------------------------------------------------------------------- */
//                             QNG DATA STORE
/* ----------------------------------------------------------------------- */

export const useQngDataStore = create<QngDataState>()(
  devtools(
    persist(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      immer((_set) => ({
        authToken: undefined,
        appSettings: {},
        culture: undefined,
        clientId: undefined,
        isTokenAnonymous: false,
        notifications: [],
        notificationsBlacklist: {},
        notificationHistory: [],
        onboardingOverlayDismissedTime: dayjs(0),
        storedResponseData: {},
        attractionsSort: undefined,
      })),
      {
        name: import.meta.env.VITE_DATA_STORE_NAME_PREFIX,
        // A custom storage so we can store more complex objects with custom serialization
        storage: createJSONStorage(() => localStorage, {
          reviver: (key, value) => {
            /**
             * This could be more generic in the future and detect any ISO8601 date strings
             * but for now it ONLY handles the onboard overlay dismissed time
             */
            if (
              key === 'onboardingOverlayDismissedTime' &&
              typeof value === 'string'
            ) {
              // NOTE: Can we validate if the ISO string is valid prior to possibly throwing an error ?
              try {
                return dayjs(value);
              } catch (e) {
                log.error(
                  'Error hydrating store ISO Timestamp as DayJS object',
                  e,
                );
                return value;
              }
            }
            return value;
          },
          replacer: (key, value) => {
            /**
             * This could be more generic in the future and support any DayJS date in our
             * store, but for now it ONLY handles the onboard overlay dismissed time
             */
            if (
              key === 'onboardingOverlayDismissedTime' &&
              dayjs.isDayjs(value)
            ) {
              return value.toISOString();
            }
            return value;
          },
        }),
        partialize: (state) => ({
          /*
           * Only selectively persist specific parts of the store
           * Make sure we add anything we want to persist here
           */
          authToken: state.authToken,
          isTokenAnonymous: state.isTokenAnonymous,
          storedResponseData: state.storedResponseData,
          onboardingOverlayDismissedTime: state.onboardingOverlayDismissedTime,
          culture: state.culture,
          clientId: state.clientId,
          attractionsSort: state.attractionsSort,
          /**
           * Persisting the app settings means the site will continue
           * using the last settings on a refresh while we await a
           * reload (cache allowing) in-case any settings have changed.
           */
          appSettings: state.appSettings,
          /**
           * TODO: do ACTUALLY want to persist notifications in Local storage ?
           * Currently this only persists informational ones (errors are transient).
           * But in reality I would think we would want to "recalc" the notifications
           * on a fresh site load ?
           */
          notifications: state.notifications.filter(
            (x) => x.type === 'informational',
          ),
        }),
        /*
         * onRehydrateStorage: (state) => {
         *   loglevel.debug("Rehydrated state", state);
         * },
         */

        /*
         * NOTE: If we want to introduce breaking changes to the data store
         *       that may affect users (unlikely at this stage), then we should
         *       look to use the version property and migrate function to handle the
         *       migration of data from one version to another.
         *       https://docs.pmnd.rs/zustand/integrations/persisting-store-data#version
         */
      },
    ),
  ),
);
/**
 * Actions
 * - We don't co-locate the actions IN the store for the benefits outlined here
 *  - https://docs.pmnd.rs/zustand/guides/practice-with-no-store-actions
 *
 * Essentially by not putting them IN the store it means we don't necessarily
 * have to import the store/hook if it's not needed in a place we may want to
 * fire an action.
 */

/**
 * Set the Auth Token (and if it's anonymous) in the global store.
 * @param {Object} data - The new auth token to set
 * @param {string} data.authToken - The new auth token to set (or undefined to un-set it)
 * @param {boolean} data.isTokenAnonymous - Is the token anonymous (I.E. not signed in and provided by the API)
 */
export function setAuthToken({
  authToken,
  isTokenAnonymous,
}: {
  authToken: string | undefined;
  isTokenAnonymous: boolean;
}) {
  useQngDataStore.setState(
    () => ({ authToken, isTokenAnonymous }),
    false,
    'setAuthToken',
  );
}

export function setStoredResponseDataItem(key: string, value: unknown) {
  useQngDataStore.setState(
    (state) => {
      state.storedResponseData[key] = value;
    },
    false,
    'setStoredResponseDataItem',
  );
}

export function removeStoredResponseDataItem(key: string) {
  useQngDataStore.setState(
    (state) => {
      delete state.storedResponseData[key];
    },
    false,
    'removeStoredResponseDataItem',
  );
}

/**
 * Sets the time when the onboarding overlay was last dismissed.
 * If you pass nothing/undefined then it will be set to the current time.
 *
 * @param time - The time to mark the overlay as dismissed at (or undefined to set to the current time)
 */
export function setOnboardingOverlayDismissedTime(time?: dayjs.Dayjs) {
  useQngDataStore.setState(
    (state) => {
      state.onboardingOverlayDismissedTime = time ?? dayjs();
    },
    false,
    'setOnboardingOverlayDismissedTime',
  );
}

/**
 * Add a new notification to the notification stack.
 *
 * @param notification
 * @returns The ID of the new notification
 */
export function addNotification(
  notification: Omit<GlobalNotification, 'id' | 'addedAtTime'>,
  options?: { notToShowAgainFor?: Duration },
): Guid {
  const newNotificationId = uuidv4();

  const notToShowAgainUntil = options?.notToShowAgainFor
    ? dayjs().add(options.notToShowAgainFor)
    : undefined;

  useQngDataStore.setState(
    (state) => {
      /*
       * If 'context' is set, remove any existing entries matching that context
       * unless exists in the blacklist and is still active
       */
      const newContext = notification.context;
      if (newContext) {
        if (
          state.notificationsBlacklist &&
          state.notificationsBlacklist[newContext]
        ) {
          const blacklistUntil = dayjs(
            state.notificationsBlacklist[newContext] as string,
          );

          if (blacklistUntil.isAfter(dayjs())) {
            return;
          }
        } else if (notToShowAgainUntil) {
          state.notificationsBlacklist[newContext] =
            notToShowAgainUntil.toISOString();
        }

        state.notifications = state.notifications.filter(
          (n) => n.context !== newContext,
        );
      }

      state.notifications.push({
        ...notification,
        id: newNotificationId,
        addedAtTime: dayjs().toISOString(),
      });

      addNotificationToHistory({
        ...notification,
        id: newNotificationId,
        addedAtTime: dayjs().toISOString(),
      });
    },
    false,
    'addNotification',
  );

  return newNotificationId;
}

/**
 * Remove a notification from the notification stack.
 *
 * @param notification
 */
export function removeNotification(id: Guid) {
  useQngDataStore.setState(
    (state) => {
      state.notifications = state.notifications.filter((n) => n.id !== id);
    },
    false,
    'removeNotification',
  );
}

/**
 * Clear all notifications from the notification stack.
 */
export function clearNotifications() {
  useQngDataStore.setState(
    (state) => {
      state.notifications = [];
    },
    false,
    'clearNotifications',
  );
}

/**
 * Remove all notifications of a specific type from the notification stack.
 * (E.g. Useful to remove error notifications on route change)
 */
export function clearNotificationsOfType(type: GlobalNotificationType) {
  useQngDataStore.setState(
    (state) => {
      state.notifications = state.notifications.filter((n) => n.type !== type);
    },
    false,
    'clearNotificationsOfType',
  );
}

/**
 * Remove all notifications of a specific type from the notification stack.
 * (E.g. Useful to remove error notifications on route change)
 */
export function clearNotificationsWithContext(context: string) {
  useQngDataStore.setState(
    (state) => {
      state.notifications = state.notifications.filter(
        (n) => n.context !== context,
      );
    },
    false,
    'clearNotificationsWithContext',
  );
}

/**
 *  Clear dismissed notifications from the blacklist when navigating away
 *  so they can reappear if the user returns. The blacklist holds notifications
 *  dismissed temporarily with a 'dismissed until' time.
 */
export function clearBlacklistNotificationsByContext(context: string) {
  useQngDataStore.setState(
    (state) => {
      if (
        state.notificationsBlacklist &&
        state.notificationsBlacklist[context]
      ) {
        delete state.notificationsBlacklist[context];
      }
    },
    false,
    'clearNotificationsBlacklist',
  );
}

/**
 * NOT EXPORTED
 * Tracks the last 50 notifications in a history stack.
 * Considered a potential feature to allow viewing of previous notifications
 * that returned and ID was already shown to the user.
 * @param notification
 */
function addNotificationToHistory(notification: GlobalNotification) {
  useQngDataStore.setState(
    (state) => {
      if (state.notificationHistory.length > 50) {
        state.notificationHistory.shift();
      }
      state.notificationHistory.push(notification);
    },
    false,
    'addNotificationToHistory',
  );
}

export function setAppSettings(settings: AppSettings) {
  useQngDataStore.setState(
    (state) => {
      state.appSettings = settings;
    },
    false,
    'setAppSettings',
  );
}

export function clearAppSettings() {
  useQngDataStore.setState(
    (state) => {
      state.appSettings = {};
    },
    false,
    'clearAppSettings',
  );
}

export function updateAppSetting(key: AppSettingsKey, value: AppSettingsValue) {
  useQngDataStore.setState(
    (state) => {
      if (!state.appSettings) {
        state.appSettings = {};
      }
      state.appSettings[key] = value;
    },
    false,
    'updateAppSetting',
  );
}

/**
 * A single place to ensure we take care of all the necessary particulars
 * when signing out a user.
 *
 * Anything sensitive or user-specific should be cleared here.
 */
export function signOut() {
  useQngDataStore.setState(
    (state) => {
      state.authToken = undefined;
      state.isTokenAnonymous = false;

      state.notificationHistory = [];
      state.notificationsBlacklist = {};
      state.notifications = [];

      state.storedResponseData = {}; // DEPRECATED
    },
    false,
    'signOut',
  );
}

export function setCulture(culture: string | undefined) {
  useQngDataStore.setState(
    (state) => {
      state.culture = culture;
    },
    false,
    'setCulture',
  );
}

export function setClientId(clientId: string | undefined) {
  useQngDataStore.setState(
    (state) => {
      state.clientId = clientId;
    },
    false,
    'setClientId',
  );
}

export function setAttractionsSort(sort: string | undefined) {
  useQngDataStore.setState(
    (state) => {
      state.attractionsSort = sort;
    },
    false,
    'setAttractionsSort',
  );
}
