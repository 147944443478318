import { QueryKey } from '@tanstack/react-query';

import { CommonQueryProps } from '../types';

export function commonQueryPropsToKey(props: CommonQueryProps): QueryKey {
  // TODO: Any sanitization or validation of the props should be done here

  return [
    {
      authToken: props.authToken,
      isAuthTokenAnonymous: props.isAuthTokenAnonymous,
      culture: props.culture,
      clientId: props.clientId,
    },
  ];
}
