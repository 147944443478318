import { CommonQueryProps } from '../types';

/**
 * Shared function to generate any common headers and reduce
 * duplication of code such as omitting an undefined authToken
 * from all the defined API calls.
 *
 * This should simple generate the Key-Value pairs of headers
 * that are common to all API calls, based on options provided.
 *
 * An example use case is omitting the Authorization header if
 * the authToken is undefined.
 *
 * @param opts - Options object
 * @returns A Record of headers to be used in an axios request
 */
export function getCommonHeaders({
  authToken,
  clientId,
  culture,
}: CommonQueryProps): Record<string, string> {
  const headers: Record<string, string> = {};

  if (authToken) {
    headers['Authorization'] = `Bearer ${authToken}`;
  }

  /*
   * All deployed variants should now have a clientId (as the schema requires it), so
   * in theory this will always be set to something, the fallback to an empty string
   * is so the header is at-least sent (albeit empty) in the case something goes wrong
   * or is badly configured.
   */
  headers['Client-Id'] = clientId ?? '';

  if (culture) {
    headers['Accept-Language'] = culture;
  }

  return headers;
}
