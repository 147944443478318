import { QueryKey, useSuspenseQuery } from '@tanstack/react-query';

import { getPayment, resourceQueryKey } from '@/api/functions/payment';
import { CommonQueryProps } from '@/api/types';
import { commonQueryPropsToKey } from '@/api/utils/common-query-props-to-key';
import {
  useSelectQngAuth,
  useSelectQngCommonQueryProps,
} from '@/stores/selectors';
import { ApiQueryDefinition } from '@/types/core';

/**
 * This function is used to generate the query key for this query.
 *
 * @param object - Any parameters needed to generate the query key
 * @returns a valid string[] query key, used in the wrapped query further below
 */
export function getQueryKey(params: CommonQueryProps): QueryKey {
  return [...resourceQueryKey(), ...commonQueryPropsToKey(params)];
}

const queryDefinition: ApiQueryDefinition<
  typeof getQueryKey,
  typeof getPayment
> = (props: CommonQueryProps) => ({
  queryKey: getQueryKey(props),
  queryFn: ({ signal }: { signal: AbortSignal }) =>
    getPayment({ signal, ...props }),
});

export function useGetPayment() {
  const { authToken } = useSelectQngAuth();
  const commonQueryProps = useSelectQngCommonQueryProps();

  const {
    data: queryData,
    isFetching,
    isPending,
    isRefetching,
    isStale,
  } = useSuspenseQuery(queryDefinition({ authToken, ...commonQueryProps }));

  const { actions, client_data: clientData, data, links } = queryData;

  return {
    actions,
    clientData,
    data,
    links,
    isFetching,
    isPending,
    isRefetching,
    isStale,
  };
}
