import { ErrorIcon, Label } from '@pebl/ui';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ApiResourceActionOptionsProperty } from '@/api/types';
import { isBoolean } from '@/utils/typeguards';

import { CommonActionPropertyOptions } from '../../types';
import { ActionPropertyCheckboxView } from './checkbox-view';

type ActionPropertyCheckboxProps = {
  id: string;
  data: ApiResourceActionOptionsProperty;
  options: CommonActionPropertyOptions;
};

export function ActionPropertyCheckbox({
  data,
  id,
  options,
}: ActionPropertyCheckboxProps) {
  const { t } = useTranslation();
  const { required } = options;
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Controller
        name={id}
        control={control}
        rules={{
          required: isBoolean(required)
            ? {
                value: required,
                message: t('actions.actions_form.errors.required'),
              }
            : undefined,
        }}
        render={({ field }) => (
          <ActionPropertyCheckboxView
            label={data.title}
            error={options.isError}
            innerRef={field.ref}
            // We can't just spread field here due to ref problems
            name={field.name}
            value={field.value}
            disabled={field.disabled}
            onChange={field.onChange}
            onBlur={field.onBlur}
          />
        )}
      />
      {errors[id] && (
        <Label className="pebl-caption flex flex-row items-center gap-1 py-2 text-destructive">
          <ErrorIcon outlined className="size-4" />
          {errors[id]?.message?.toString()}
        </Label>
      )}
    </>
  );
}
